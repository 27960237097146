import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FileType } from '../constants/FileType';
import { RegisterRequest } from '../models/Auth';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly resourceUrl: string = '/v1/users';
  private readonly resourceAdminUrl: string = '/v1/admin';

  // prettier-ignore
  constructor(
    private apiService: ApiService
  ) {
  }

  getUserInfo() {
    return this.apiService.get(`${this.resourceUrl}/get-user-info`);
  }

  register(registerRequest: RegisterRequest, token: string): Observable<any> {
    return token
      ? this.apiService.post(`${this.resourceUrl}/register/${token}`, registerRequest)
      : this.apiService.post(`${this.resourceUrl}/register-user`, registerRequest);
  }

  logout(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/logout`);
  }

  completeRegistration(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/complete-registration`, body);
  }

  confirmEmail(token: string, update: boolean) {
    return update
      ? this.apiService.get(`${this.resourceUrl}/confirm-email-update/${token}`)
      : this.apiService.get(`${this.resourceUrl}/confirm-email/${token}`);
  }

  resendEmail(emailOrToken: string) {
    return this.apiService.post(`${this.resourceUrl}/resend-email/${emailOrToken}`, null);
  }

  updateUserInfo(uuid, body) {
    return uuid
      ? this.apiService.post(`${this.resourceAdminUrl}/user-profile-update/${uuid}`, body)
      : this.apiService.post(`${this.resourceUrl}/account-update`, body);
  }

  getUploadProfileImageUrl() {
    return `${environment.server}${this.resourceUrl}/upload-user-file`;
  }

  changePassword(body) {
    return this.apiService.post(`${this.resourceUrl}/change-password`, body);
  }

  getResetPasswordLink(email: string): Observable<any> {
    return this.apiService.post(`${this.resourceAdminUrl}/reset-password-link`, { email });
  }

  getUserOptions() {
    return this.apiService.get(`${this.resourceUrl}/user-options`);
  }

  getDropdownsAndUserInfo(uuid: string) {
    const userDropdowns = this.apiService.get(`${this.resourceUrl}/dropdowns`);
    const userDetails = uuid
      ? this.apiService.get(`${this.resourceAdminUrl}/get-user-profile/${uuid}`)
      : this.apiService.get(`${this.resourceUrl}/get-user-info`);
    return forkJoin([userDropdowns, userDetails]);
  }

  deleteUserFile(fileUuid: string, fileType: FileType, userUuid: string) {
    return userUuid
      ? this.apiService.delete(`${this.resourceUrl}/delete-admin-user-image`, { fileUuid, fileType, userUuid })
      : this.apiService.delete(`${this.resourceUrl}/delete-user-file`, { fileUuid, fileType });
  }

  deleteFileByAdmin(fileUuid: string, fileType: FileType, userUuid: string) {
    return this.apiService.delete(`${this.resourceUrl}/delete-admin-user-image`, { fileUuid, fileType, userUuid });
  }

  toggleWatchlist(body) {
    return this.apiService.post(`${this.resourceUrl}/toggle-watchlist`, body);
  }

  getInvitedUserDetails(token: string) {
    return this.apiService.post(`${this.resourceUrl}/get-invited-user-details/${token}`);
  }

  deleteAccount() {
    return this.apiService.delete(`${this.resourceUrl}/delete-account`);
  }

  saveGlobalSettings(body) {
    return this.apiService.put(`${this.resourceUrl}/save-global-settings`, body);
  }

  resendInvitation(payload: any) {
    return this.apiService.post(`${this.resourceUrl}/resend-invitation`, null, payload);
  }

  deleteInvitation(payload: any) {
    return this.apiService.post(`${this.resourceUrl}/delete-invitation`, payload);
  }

  getLanguageJson(lang: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/json-language`, { languageCode: lang });
  }

  getUserSearchLog(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/get-user-search-log`);
  }

  clearUserSearchLogs(): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/clear-search-log`);
  }
}
